.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
    overflow: hidden;
}

.video-container iframe, .video-container object, .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}