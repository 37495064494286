.nav-link-overrides {
    cursor: pointer;
    text-decoration: none;
}

.nav-link-overrides:hover {
    text-decoration: underline;
}

.nav-link-overrides-active, .nav-link-overrides-active:hover {
    color: inherit;
    text-decoration: none;
    font-weight: 500;
}